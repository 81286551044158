<template>
  <div class="signInToLavishGiftsBox">
    <div class="bgc" :class="[bgColor ? 'bgColor' : '']">
      <div class="topTitle">{{ $t("signInToLavishGifts.default[0]") }}</div>
    </div>
    <div class="content" ref="content">
      <div class="activityCenter_Logo">
        <!-- 判断使用的哪个语言 -->
        <img
          v-if="Language == 'cn'"
          src="../../../public/static/images/makeMoney_cn.png"
        />
        <img
          v-else-if="Language == 'vn'"
          style="width: 70%;"
          src="../../../public/static/images/makeMoney_ptb.png"
        />
      </div>
      <div class="getMoney_box">
        <!-- <div class="getMoney_item" v-for="(item,index) in 7">
					<div class="money"><span>0.{{item}}</span><span class="unit">{{$t('signInToLavishGifts.default[1]')}}</span></div>
					<div class="money_btn" :class="{'money_btn_active':index==active}" @click="showDialog=true">{{active==index?$t('signInToLavishGifts.default[4]'):$t('signInToLavishGifts.default[3]')}}</div>
				</div> -->
        <div
          class="getMoney_item"
          v-for="(item, index) in signData.sign_config"
        >
          <div style="height: 60%;display: flex;align-items: center;">
            <div class="money">
              {{ item
              }}<span
                v-html="
                  $t('signInToLavishGifts.default[1]', {
                    currency_name: currency_name,
                  })
                "
                class="money_cn"
              ></span>
            </div>
          </div>
          <div style="height: 40%;display: flex;align-items: center;">
            <div
              class="money_btn money_btn_active"
              v-if="signData.user_sign.total_sign_num == index - 1"
              @click="insertUserSignin(item)"
            >
              {{ $t("signInToLavishGifts.default[4]") }}
            </div>
            <div
              class="money_btn"
              v-else-if="signData.user_sign.total_sign_num < index - 1"
            >
              {{ $t("signInToLavishGifts.default[3]") }}
            </div>
            <div class="money_btn" v-else>
              {{ $t("signInToLavishGifts.default[2]") }}
            </div>
          </div>
          <!-- <div class="money_btn" :class="{'money_btn_active':signData.user_sign.sum_sgin_day==index-1}" @click="showDialog=true">{{active==index?$t('signInToLavishGifts.default[4]'):$t('signInToLavishGifts.default[3]')}}</div> -->
        </div>
      </div>
      <div class="title_box">
        <div class="signIn_day">
          {{
            $t("signInToLavishGifts.default[5]", {
              day: signData.user_sign ? signData.user_sign.total_sign_num : "",
            })
          }}
        </div>
        <div class="title">{{ $t("signInToLavishGifts.default[6]") }}</div>
      </div>
      <div class="explain">
        <p
          class="explain_item"
          v-for="(item, index) in $t('signInToLavishGifts.rule')"
        >
          {{ index + 1 }}、{{ item }}
        </p>
      </div>
      <van-dialog
        class="dialog"
        v-model="showDialog"
        :title="$t('signInToLavishGifts.default[7]')"
        show-cancel-button
        width="300px"
        closeOnClickOverlay
        :confirmButtonText="$t('signInToLavishGifts.default[10]')"
        :cancelButtonText="$t('signInToLavishGifts.default[9]')"
        @confirm="goShareface()"
      >
        <div>{{ $t("signInToLavishGifts.default[8]") }}</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "signInToLavishGifts",
  components: {},
  props: ["id"],
  data() {
    return {
      bgColor: false, //是否显示顶部栏颜色
      showDialog: false,
      signData: [], //签到信息list
      sgin_id: "", //保存传给分享页面调用分享日志接口的id
      money: 0, //今天领取的金额，分享用
      Language: localStorage["Language"], //当前语言
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
    };
  },
  computed: {},
  created() {
    //标题
    this.$parent.navBarTitle = "";
    // this.GetWithdrawType()
    this.getActivityGet(this.id);
  },
  mounted() {
    // console.log(this.$refs.bgc)
    this.pageScroll();
  },
  activated() {},
  beforeDestroy() {
    // 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //活动详情
    getActivityGet(id) {
      let json = {
        id,
      };
      this.$Model.ActivityGet(json, (data) => {
        console.log(data);
        this.signData = data.data;
      });
    },
    //活动签到
    insertUserSignin(money) {
      // 今天领取的金额，分享用
      this.money = money;
      // this.showDialog=true
      this.$Model.UserSignin((data) => {
        if (data.code == 0) {
          this.$Dialog.Toast(this.$t("signInToLavishGifts.default[11]"));
          return;
        }
        // 打开提示签到成功
        this.showDialog = true;
        this.sgin_id = data.data.sgin_id;
        this.signData.user_sign.total_sign_num += 1;
      });
    },
    pageScroll() {
      // 监听滚动事件，用handleScroll这个方法进行相应的处理
      window.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      // 监听到页面滚动后的处理事件
      if (this.$refs.content.getBoundingClientRect().top == -13) {
        this.bgColor = false;
      } else {
        this.bgColor = true;
      }
    },
    //分享页面
    goShareface() {
      // console.log(this.signData.user_sign.total_sign_num)
      this.$router.push({
        path: "/user/Shareface",
        query: { id: this.sgin_id, urlCode: 0, money: this.money },
      });
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
.signInToLavishGiftsBox {
  overflow: auto;
  height: 100vh;

  /* 	background-color: #eff0f2;
		background: url(../../../public/static/images/signInToLavishGiftsBgc.png) no-repeat -36px 0px;
		box-sizing: 100% 100%;
		position: fixed;
		height: 100%;
		width: 100%; */
}

.signInToLavishGiftsBox .bgc {
  width: 100%;
  background-color: transparent;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}
.bgColor {
  background-color: #4e51bf !important;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.height {
  height: 46px;
}
.signInToLavishGiftsBox .content {
  background: url(../../../public/static/images/signInToLavishGiftsBgc.png)
    no-repeat;
  width: 100%;
  /* height: 100%; */
  background-size: 100% 100%;
  padding: 20px 24px 90px;
  position: relative;
  top: -13px;
}
.BroadBarItem {
  width: 100%;
  border-radius: 10%;
  padding: 0 5%;
}
.content >>> .van-swipe {
  position: relative;
  top: 85px;
  height: auto !important;
}
.content .getMoney_box {
  margin-top: 190px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 4%; */
}
.content .getMoney_item {
  width: 80px;
  height: 75px;
  background: url(../../../public/static/icon/signInToLavishGifts_item_box.png)
    no-repeat;
  background-size: 100%;
  margin: auto;
  margin-bottom: 10px;
}
.content .money {
  width: 100%;
  height: auto;
  background-size: 100% 100%;
  text-align: center;
  line-height: 0.9;
  font-size: 16px;

  font-weight: bold;
  color: #fe889d;
}
.content .money span {
  font-size: 13px;
}
.content .money .money_cn {
  font-size: 16px;
}
.content .money_btn {
  width: 77%;
  padding: 1px 0;
  background: #624fe2;
  border-radius: 6px;
  color: #ffffff;
  margin: 4px auto;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  transform: scale(0.8);
}
.content .money_btn_active {
  background: #f1bf5b;
  color: #ffffff;
}
.content .title_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .title_box .signIn_day {
  color: #4e51bf;
  font-size: 16px;
  font-weight: bold;
  margin: 17px 0 130px;
  text-align: center;
  width: 235px;
  padding: 4px 0px;
  border-radius: 30px;
  background-color: red;
  box-sizing: border-box;
  border: 2px solid #a500ff;
  background: linear-gradient(-90deg, #ffe9ce 0%, #b3b5fc 99%);
}
.content .title_box .signIn_day span {
  vertical-align: -1px;
}
.content .title_box .title {
  padding: 9px 0;
  width: 170px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 17px;
  /* font-weight: bold; */
  border-radius: 45px;
  background: #b21bfc;
  position: relative;
  top: -20px;
}
.content .explain {
  padding: 0 3%;
  margin-top: 25px;
  padding-bottom: 20px;
  position: relative;
  top: -30px;
  left: -5px;
}
.content .explain .explain_item {
  font-size: 13px;
  color: #fff;
  margin-bottom: 6px;
  line-height: 1.4;
}
.dialog >>> .van-dialog__header {
  font-size: 20px;
  colot: #000;
  font-weight: bold;
}
.dialog >>> .van-dialog__content {
  padding: 20px 50px 30px;
  font-size: 16px;
  color: #333;
}
.dialog >>> .van-dialog__footer::after {
  display: none;
}
.dialog >>> .van-button::after {
  display: none;
}
.dialog >>> .van-dialog__footer {
  padding: 0 20px 25px;
}
.dialog >>> .van-button {
  background-color: #4e51bf;
  color: #fff;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
}
.dialog >>> .van-button:first-child {
  margin-right: 30px;
}
.getMoney1_item {
}
/* .dialog>>>.van-button{
		width: 30%;
	} */
.activityCenter_Logo {
  /* margin-top: 70px; */
  position: relative;
  top: 90px;
}
.activityCenter_Logo img {
  width: 56%;
  max-width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.van-dialog >>> .van-button__content .van-button__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.van-dialog >>> .van-button {
  overflow: hidden;
}
</style>
